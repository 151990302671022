import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { GridContainer, GridItem } from '@entur/grid';
import { BugIcon, ValidTicketIcon } from '@entur/icons';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
query logoFiles {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/ikoner"}, extension: {eq: "svg"}}) {
    images: nodes {
      name
      publicURL
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader" />
    <p>{`Ikoner blir brukt for å maksimere forståelsen og redusere kognitiv belastning når du trenger å rette
oppmerksomhet mot en bestemt handling, kommando eller seksjon. Se en liste over alle ikoner og last
dem ned i `}<a parentName="p" {...{
        "href": "/komponenter/ressurser/icons"
      }}>{`ikonoversikten vår`}</a>{`.`}</p>
    <h2 {...{
      "id": "ikonstil"
    }}>{`Ikonstil`}</h2>
    <p>{`Entur sine ikoner kombinerer et profesjonelt og lekent uttrykk. Ikonene våre er stilrene med avrundede hjørner
som gjør dem lette å lese. Ikonene skal være enkle å kjenne igjen og huske, slik at folk raskt forstår
hva de betyr og husker dem til senere.`}</p>
    <ImageDisplay src={props.data.files.images.find(image => image.name === "ikonoversikt")?.publicURL} alt="Et oversiktsbilde som viser et større utvalg at tilgjenglige Entur-ikoner" preset="full-width-image" mdxType="ImageDisplay" />
    <h2 {...{
      "id": "ikonfarge"
    }}>{`Ikonfarge`}</h2>
    <p>{`Entur sine ikoner benytter kun én farge om gangen. Standardfargen for Entur-ikonene er Entur
Blå / Lavender-90 `}<inlineCode parentName="p">{`#181C56`}</inlineCode>{` på lys bakgrunn og hvit `}<inlineCode parentName="p">{`#ffffff`}</inlineCode>{` på mørk bakgrunn. Unntaket er transportikoner
som benytter spesifikke transportfarger. `}</p>
    <ImageDisplay src={props.data.files.images.find(image => image.name === "to_farger")?.publicURL} alt="En sammenligning av to ikoner der det venstre er en billett plassert på en lys bakgrunn med Entur Blå som strekfarge og det høyre er en bille pøassert på en Entur Blå bakgrunn med hvit strekfarge" preset="full-width-image" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "universell-utforming"
    }}>{`Universell utforming`}</h3>
    <p>{`Når du bruker ikoner i arbeidet ditt, spesielt i digitale produkter, er det viktig å sørge for
at ikonene har tilstrekkelig kontrast mot bakgrunnen. Dette sikrer god lesbarhet og at designet
oppfyller kravene for universell utforming. Du kan sjekke kontrasten ved å bruke vår
`}<a parentName="p" {...{
        "href": "/universell-utforming/kontrast-sjekker"
      }}>{`kontrastsjekker`}</a>{`.`}</p>
    <h2 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h2>
    <h3 {...{
      "id": "gridsystem"
    }}>{`Gridsystem`}</h3>
    <p>{`Entur-ikonene er bygd ved hjelp av et gridsystem som gir retningslinjer for forskjellige former,
inkludert rektangler, sirkler og trekanter. Gridsystemet omfatter også `}<em parentName="p">{`padding`}</em>{` for å sikre god
balanse og lesbarhet for ikonene.`}</p>
    <ImageDisplay src={props.data.files.images.find(image => image.name === "gridsystem")?.publicURL} alt="En visuell fremstilling av gridsystemet som brukes for ikoner. Det består av ulike rettningslinjer og srikler som veileder hvor ikonet skal ligge innenfor." preset="full-width-image" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "størrelser"
    }}>{`Størrelser`}</h3>
    <p>{`Entur-ikonene tegnes i et gridsystem med størrelsen 32 px × 32 px, og de skaleres lineært opp eller
ned til ulike størrelser. I dag bruker vi fire forskjellige størrelser for ikoner i designsystemet:
32 px, 24 px, 20 px og 16 px.`}</p>
    <ImageDisplay src={props.data.files.images.find(image => image.name === "storrelser")?.publicURL} alt="En visuell fremstilling av billettikonet i de fire ulike størrelsene plassert inne i gridsystemets rettningslinjer." preset="full-width-image" mdxType="ImageDisplay" />
    <h3 {...{
      "id": "ikonoppbygging"
    }}>{`Ikonoppbygging`}</h3>
    <ImageDisplay src={props.data.files.images.find(image => image.name === "oppbygging")?.publicURL} alt="En tabelloversikt som viser ulike nøkkeltall for tykkelser på streker i ikonene under ulike størrelser." preset="full-width-image" mdxType="ImageDisplay" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      